<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import {
  PTooltip,
  IconTelegramXL as TelegramIcon,
  IconWhatsAppBusinessApiXL as WabaIcon,
} from '@profeat/ui-kit';

import {
  Channel,
  ChannelState,
  ChannelsTransportType,
  MessageLimitTier,
} from '~/entities/channel';
import { useUser } from '~/stores/user.store';

const URL_HOME = 'https://business.facebook.com/wa/manage/home';
const URL_CONFIRM = 'https://business.facebook.com/settings/security';
const ICONS = {
  [ChannelsTransportType.telegram]: TelegramIcon,
  [ChannelsTransportType.wapi]: WabaIcon,
};

const userStore = useUser();

const props = defineProps<{ channel: Channel; divider: boolean }>();

const emits = defineEmits<{
  (event: 'delete', channelId: string): void;
  (event: 'edit', channelId: string): void;
  (event: 'switch', channelId: string): void;
  (event: 'activate', channelId: string): void;
  (event: 'deactivate', channelId: string): void;
}>();

const ChannelIcon = computed(() => {
  // @ts-ignore
  return ICONS[props.channel.transport];
});

// Канал активный
// Зеленый статус "Активен"
const isActive = computed(() => props.channel.state === ChannelState.active);

// Канал не активен
// Красный статус "Заблокирован"
const isDisabled = computed(() => {
  return [
    ChannelState.disabled,
    ChannelState.blocked,
    ChannelState.rejected,
  ].includes(props.channel.state);
});

const buttons = computed(() => {
  const { channel } = props;

  const isTelegram = channel.transport === ChannelsTransportType.telegram;
  const isWaba = channel.transport === ChannelsTransportType.wapi;

  return [
    {
      condition: !channel.isSelected && isWaba && isActive.value,
      width: '69px',
      text: 'common.select',
      action: onSwitch,
    },
    {
      condition: !channel.isSelected && isTelegram && isActive.value,
      width: '69px',
      text: 'common.select',
      action: onActivate,
    },
    {
      condition: channel.isSelected && isTelegram,
      width: '85px',
      text: 'common.off',
      action: onDeactivate,
    },
  ];
});
const currentButton = computed(() => {
  return buttons.value.find((button) => button.condition);
});

// Флаг для отображения кнопок
// Кнопки не отображаются для интегрированных юзеров
const { isWzIntegration } = storeToRefs(userStore);

const onDelete = () => {
  emits('delete', props.channel.id);
};

const onEdit = () => {
  emits('edit', props.channel.id);
};

const onSwitch = () => {
  emits('switch', props.channel.id);
};

const onActivate = () => {
  emits('activate', props.channel.id);
};

const onDeactivate = () => {
  emits('deactivate', props.channel.id);
};
</script>

<template>
  <div class="profile-channel__list-item">
    <div :class="['profile-channel__list-item-info', { smoked: !channel.isSelected }]">
      <div class="profile-channel__list-item-icon">
        <ChannelIcon />
      </div>
      <div class="profile-channel__list-item-name">{{ channel.title }}</div>
      <div v-if="channel.isSelected" class="profile-channel__list-item-status">
        <PTag v-if="isActive" :color="'green'" text="Активен" />
        <PTag v-else-if="isDisabled" :color="'pink'" text="Заблокирован" />
      </div>
    </div>
    <div class="profile-channel__list-item-btn">
      <PButton
        v-if="currentButton && currentButton.condition"
        :size="'x-small'"
        :color="'secondary'"
        :style="{ width: currentButton.width }"
        @click="currentButton.action"
      >
        {{ $t(currentButton.text) }}
      </PButton>
      <template v-if="!isWzIntegration">
        <PButton :variant="'text'" :color="'secondary'" @click="onEdit">
          <IconFilledEditMd :style="{ color: 'var(--common-asphalt)' }" />
        </PButton>
        <PButton :variant="'text'" :color="'secondary'" @click="onDelete">
          <IconTrashMd :style="{ color: 'var(--common-asphalt)' }" />
        </PButton>
      </template>
    </div>
  </div>
  <div v-if="divider" class="profile-channel__list-divider"></div>
</template>

<style scoped>
.profile-channel__list-item {
  box-sizing: border-box;
  padding: 12px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}
.profile-channel__list-item-btn {
  display: flex;
  gap: 16px;
}
.profile-channel__list-item-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-basis: calc(100% * 0.4);
  gap: 12px;
}
.profile-channel__list-item-icon {
  display: flex;
}
.profile-channel__list-item-name {
  flex-grow: 1;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
.profile-channel__list-item-current {
  width: 77px;
}

.smoked {
  opacity: 50%;
}

.profile-channel__list-item-popover {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.profile-channel__list-item-popover a {
  color: var(--el-color-primary);
}

.profile-channel__list-divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  width: 100%;
  margin: 8px 0;
}
</style>
