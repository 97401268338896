export enum ChannelsTransportType {
  whatsapp = 'whatsapp',
  wapi = 'wapi',
  instagram = 'instagram',
  tgapi = 'telegram',
  telegram = 'telegram',
}

export enum ChannelState {
  active = 'active',
  init = 'init',
  disabled = 'disabled',
  phoneUnavailable = 'phoneUnavailable',
  qr = 'qr',
  openElsewhere = 'openElsewhere',
  notEnoughMoney = 'notEnoughMoney',
  foreignphone = 'foreignphone',
  unauthorized = 'unauthorized',
  waitForPassword = 'waitForPassword',
  blocked = 'blocked',
  rejected = 'rejected',
}

export enum MessageLimitTier {
  TIER_0 = 'TIER_0',
  TIER_1K = 'TIER_1K',
  TIER_10K = 'TIER_10K',
  TIER_100K = 'TIER_100K',
  TIER_UNLIMITED = 'TIER_UNLIMITED',
  NOT_ASSIGNED = 'NOT_ASSIGNED',
}

export type ChannelTier = MessageLimitTier | string;

export interface Channel extends Record<string, any> {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  title: string;
  channelId: string;
  userId: string;
  verificationLink?: string;
  transport: ChannelsTransportType;
  state: ChannelState;
  isActivated: boolean;
  tier: ChannelTier;
  countMessagePerDay: string;
  subscriptionExpiredAt: Date | null;
  isSelected: boolean;
  companyId: string;
}
