<script lang="ts" setup>
import { ref } from 'vue';
import { IconCrossMd as CrossIcon } from '@profeat/ui-kit';

const visibleModel = ref<boolean>(false);

const show = () => {
  visibleModel.value = true;
};

const onClose = (): void => {
  visibleModel.value = false;
};

defineExpose({ show });
</script>

<template>
  <PDialog v-model="visibleModel" width="x-small" :close-on-click-outside="true">
    <template #header>
      <header>
        <p>Достигнут лимит каналов</p>
        <div class="icon" @click="onClose">
          <CrossIcon />
        </div>
      </header>
    </template>

    <template #default>
      <div class="main">
        <p>На текущий момент вам доступно один активный WABA и Telegram каналы. Чтобы сделать этот канал активным, выключите один из уже активных каналов</p>
      </div>
    </template>
  </PDialog>
</template>

<style scoped>
header {
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  box-shadow: 0 -1px 0 0 #00000014 inset;
}
header p {
  flex: 1;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
}
header .icon {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.main {
  padding: 28px 16px 8px 16px;
}
.main p {
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  text-align: left;
}
</style>
