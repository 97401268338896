import { BaseCrudApi } from './abstract/base-crud.api';
import { BroadcastStatistic } from '~/entities/broadcast-statistic';
import { CustomersFilter } from '~/entities/customer';

export type ValidateBeforeSend = {
  templateId: string;
  variables?: Record<string, unknown>;
  filters: CustomersFilter[];
  included?: string[];
  userId: string;
};

export class BroadcastsApi extends BaseCrudApi {
  constructor() {
    super('broadcasts');
  }

  async sendPreviewMessage(formData: FormData) {
    return this.api.post('preview', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  async validateBeforeSend(payload: ValidateBeforeSend) {
    return this.api
      .post<{
        validCustomerIds: string[];
        invalidCustomerIds: string[];
      }>('validate', payload)
      .then(({ data }) => data);
  }

  async getStatistics(params?: { broadcastId: string }) {
    return this.api.get<BroadcastStatistic[]>('/statistics');
  }

  async getAvailableCountMessage(channelId: string) {
    return this.api.get<number>(`channel/${channelId}/messages-count`);
  }
}
