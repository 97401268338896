import type {
  Bot,
  BotMode,
  BotRequestBody,
  BotRunTestDto,
  BotStatisticsResponse,
} from "~/entities/chatbots";
import { CatchAll } from "~/error/catch";
import { BaseCrudApi } from "./abstract/base-crud.api";

export class BotsApi extends BaseCrudApi {
  constructor() {
    super("bot");
  }

  @CatchAll()
  async getBots(take?: string, skip?: string) {
    return this.api.get<any>("", { params: { take, skip } });
  }
  async getOneBot(id: string) {
    return this.api.get<Bot>(id);
  }

  @CatchAll()
  async createBot(data: BotRequestBody) {
    return this.api.post<any>("", data);
  }

  @CatchAll()
  async testBotRun(data: BotRunTestDto) {
    return this.api.post<any>(`/run-test`, data);
  }

  @CatchAll()
  async updateBot(
    id: string,
    data: BotRequestBody,
    userId: string,
    confirm: boolean,
    mode: BotMode
  ) {
    const body = {
      ...data,
      userId: userId,
      id,
      confirm,
      mode,
    };
    return this.api.put(id, body);
  }

  @CatchAll()
  async putchBot(
    id: string,
    data: {
      status?: string;
      name?: string;
      channelId?: string;
      confirm: boolean;
      mode?: BotMode;
    }
  ) {
    return this.api.patch(id, data);
  }

  @CatchAll()
  async deleteBot(id: string) {
    return this.api.delete(id);
  }

  @CatchAll()
  async updateSchema(data: { botId: string; graph: string }) {
    return this.api.patch(`/update-schema/${data.botId}`, data);
  }

  async getStatistics(id: string) {
    return this.api.get<BotStatisticsResponse>(`/${id}/statistics`);
  }

  @CatchAll()
  async addFile(nodeId: string, file: any, id: string) {
    return this.api.post(
      `/upload-file/${id}?_nocache=${new Date().getTime()}`,
      {
        nodeId,
        headerFile: file,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }
}
