<script lang="ts" setup>
import { ref } from 'vue';
import { PButton, IconCrossMd as CrossIcon } from '@profeat/ui-kit';

const visibleModel = ref<boolean>(false);
const loading = ref<boolean>(false);
const resolvePromise = ref<(value: true) => void>();
const rejectPromise = ref<(value: false) => void>();

const show = () => {
  visibleModel.value = true;

  return new Promise((resolve, reject) => {
    resolvePromise.value = resolve;
    rejectPromise.value = reject;
  });
};
const hide = (): void => {
  visibleModel.value = false;
};

const onClose = (): void => {
  rejectPromise.value!(false);
  hide();
};
const onSelect = async (): Promise<void> => {
  loading.value = true;

  resolvePromise.value!(true);
  hide();

  loading.value = false;
};

defineExpose({ show });
</script>

<template>
  <PDialog v-model="visibleModel" width="x-small">
    <template #header>
      <header>
        <p>Выключить активный канал?</p>
        <div class="icon" @click="onClose">
          <CrossIcon />
        </div>
      </header>
    </template>

    <template #default>
      <div class="main">
        <p>При выключении канала перестанет работать бот, привязанный к этому каналу</p>
      </div>
    </template>

    <template #footer>
      <footer>
        <PButton class="button" :color="'secondary'" @click="onClose">Оставить</PButton>
        <PButton class="button" :loading="loading" @click="onSelect">Выключить</PButton>
      </footer>
    </template>
  </PDialog>
</template>

<style scoped>
header {
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  box-shadow: 0 -1px 0 0 #00000014 inset;
}
header p {
  flex: 1;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
}
header .icon {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.main {
  padding: 28px 16px;
}
.main p {
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  text-align: center;
}

footer {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  padding: 8px 16px 16px 16px;
}
footer .button {
  width: 158px;
}
</style>
