import { defineStore } from 'pinia';

import {
  Template,
  TemplateStatus,
  WazzupTemplateType,
} from '~/entities/template';
import { api } from '~/api';
import { useChannels } from '~/stores/channel.store';

type Templates = Array<Template>;

interface State {
  templates: Templates;
  channelTemplates: Templates;
  iframeUrl: string;
  isFetching: boolean;
}

const getBodyText = (components: any[]): string => {
  const component = components.find((component) => {
    if (component?.type === 'BODY' && component?.text) {
      return component;
    }
  });

  return Boolean(component) ? component.text : '';
};

export const useTemplates = defineStore('templates', {
  state: (): State => ({
    templates: [],
    channelTemplates: [],
    iframeUrl: '',
    isFetching: false,
  }),

  actions: {
    async fetchAndSync() {
      this.templates = await api.templates.findManyAndSync();
    },
    async fetchIframe(type: WazzupTemplateType) {
      const { link } = await api.templates.getIframe(type);

      this.iframeUrl = link;
    },
    async fetchChannelTemplates() {
      const channelsStore = useChannels();
      const channel = channelsStore.getWabaCurrent!;

      const templates = await api.templates.findByChannel(channel.id);
      this.channelTemplates = templates.filter(
        (template) => template.status === TemplateStatus.approved,
      );
    },

    setFetching(payload: boolean) {
      this.isFetching = payload;
    },
    setTemplates(payload: Templates) {
      this.templates = payload;
    },
  },

  getters: {
    getIframe: (state: State) => state.iframeUrl,
    getIsFetching: (state: State): boolean => state.isFetching,
    getChannelTemplates: (state: State) => state.channelTemplates,
    getTemplates: (state: State) => state.templates,
    getTemplatesMap: (state: State): { [templateId: string]: Template } => {
      return state.templates.reduce(
        (templates, currentTemplate) => ({
          ...templates,
          [currentTemplate.id]: currentTemplate,
        }),
        {},
      );
    },
  },
});
