import { defineStore } from 'pinia';
import { User } from '~/entities/user';
import { api } from '~/api';

interface State {
  user: User;
  isGuestView: boolean;
}

export const useUser = defineStore('user', {
  state: (): State => ({
    user: {} as User,
    isGuestView: false,
  }),

  actions: {
    setUser(payload: User) {
      this.user = payload;
    },

    setGuestView(payload: boolean) {
      this.isGuestView = payload;
    },

    isWzApiKeyRelevanceUpdate(status: boolean) {
      this.user.isWzApiKeyRelevance = status
    },

    updateWzApiKey(key: string) {
      return api.profile.updateWzApiKey(key)
    },
  },

  getters: {
    getUserId: (state: State) => state.user.id,
    getUserName: (state: State) => state.user.name,
    guestView: (state: State) => state.isGuestView,
    isWzIntegration: (state: State) => state.user.isWzIntegration,
    isWzApiKeyRelevance: (state: State) => state.user.isWzApiKeyRelevance,
  },
});
