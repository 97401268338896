import { BaseCrudApi } from './abstract/base-crud.api';
import { Template, WazzupTemplateType } from '~/entities/template';
import { CatchAll } from '~/error/catch';
import { CatchWzApi } from '~/error/wz-api-catch';

export class TemplatesApi extends BaseCrudApi {
  constructor() {
    super('templates');
  }

  async getIframe(type: WazzupTemplateType) {
    return this.api
      .post<{ link: string }>(`iframe/${type}`)
      .then(({ data }) => data);
  }

  @CatchWzApi()
  @CatchAll()
  async findManyAndSync() {
    return this.api.post<Template[]>('sync').then(({ data }) => data);
  }

  async findByChannel(channelId: string) {
    return this.api
      .get<Template[]>(`channel/${channelId}`)
      .then(({ data }) => data);
  }
}
