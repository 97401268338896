import { CatchWzApi } from '~/error/wz-api-catch';
import { BaseCrudApi } from './abstract/base-crud.api';
import { Channel, ChannelsTransportType } from '~/entities/channel';

export class ChannelsApi extends BaseCrudApi {
  constructor() {
    super('channels');
  }

  @CatchWzApi()
  async createChannel(
    transport: ChannelsTransportType,
    telegramBotToken?: string,
  ) {
    return this.api.post<Channel>(`${transport}`, { telegramBotToken });
  }

  @CatchWzApi()
  async findManyAndSync() {
    return this.api.post<Array<Channel>>('sync').then((result) => result.data);
  }

  async switchSelected(body: {
    channelId: string;
    transport: ChannelsTransportType;
  }) {
    return this.api
      .post<Array<Channel>>('switch-selected', body)
      .then((result) => result.data);
  }

  async activate(body: {
    channelId: string;
  }) {
    return this.api
      .post<Array<Channel>>('activate', body)
      .then((result) => result.data);
  }

  async deactivate(body: {
    channelId: string;
  }) {
    return this.api
      .post<Array<Channel>>('deactivate', body)
      .then((result) => result.data);
  }
}
