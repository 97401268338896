import { createRouter, createWebHistory } from "vue-router";

import { useNotification } from '~/stores/notification.store';
import { noActiveSubscriptionGuard } from '~/guards/no-active-subscription.guard';
import { authGuard } from '~/guards/auth.guard';
import { customersGuard } from '~/guards/customers.guard';
import { profileGuard } from '~/guards/profile.guard';
import { integrationUserGuard } from '~/guards/integration.guard';
import { lazy } from './lazy';
import { authRoutes } from './auth.routes';
import ProfileView from '~/views/ProfileView.vue';
import { authPaths, initUser } from '~/composables/initUser';

// TODO: разделить бы на объекты и массивы роуты по типу authRoutes
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    ...authRoutes,

    {
      path: "/broadcast",
      name: "broadcast",
      redirect: "/broadcast",
      children: [
        {
          path: "",
          name: "statistics",
          component: lazy(import("~/views/StatisticsView.vue")),
          meta: {
            pageTitle: "menu.mass-mailing",
          },
        },
        {
          path: 'sender',
          name: 'broadcastSender',
          component: lazy(import('~/components/broadcastV2/BroadcastV2Sender.vue')),
          meta: {
            pageTitle: "menu.send",
            withBackRedirect: true,
            background: "#FFFFFF",
          },
        },
      ],
    },
    {
      path: "/chats",
      name: "chats",
      component: lazy(import("~/views/ChatsView.vue")),
      meta: {
        pageTitle: "menu.chats",
      },
    },
    {
      path: "/chat-bots",
      name: "ChatBots",
      component: lazy(import("~/views/chat-bots/ChatBotsView.vue")),
      meta: {
        pageTitle: "menu.chat-bots",
      },
      children: [
        {
          path: '/chat-bots/:id',
          name: 'ChatBotsEditor',
          component: lazy(import('~/views/chat-bots/ChatbotEditor.vue')),
          meta: {
            canEditTitle: true,
            withBackRedirect: true,
          },
        },
      ],
    },
    {
      path: '/templates',
      name: 'templates',
      component: lazy(import('~/views/TemplatesView.vue')),
      meta: {
        pageTitle: "menu.templates",
      },
    },
    {
      path: "/trigger-broadcast",
      name: "triggerBroadcast",
      redirect: "/trigger-broadcast/list",
      meta: {
        pageTitle: "menu.trigger-broadcast",
      },
      children: [
        {
          path: "/trigger-broadcast/list",
          name: "triggerBroadcastList",
          component: lazy(
            import("~/views/triggerBroadcast/TriggerBroadcastView.vue")
          ),
        },
        {
          path: "/trigger-broadcast/create/:id",
          name: "triggerBroadcastCreate",
          component: lazy(import("~/views/triggerBroadcast/TGCreateView.vue")),
        },
        {
          path: "/trigger-broadcast/change/:id",
          name: "triggerBroadcastChange",
          component: lazy(import("~/views/triggerBroadcast/TGChangeView.vue")),
          meta: {
            withBackRedirect: true,
          },
        },
      ],
    },
    {
      path: "/errors/chatbot",
      name: "chatbot-error",
      component: lazy(import("~/components/app/error/ChatBot.vue")),
      meta: {
        layout: "AuthLayout",
      },
    },
    {
      path: "/errors/forbidden",
      name: "forbidden-error",
      component: lazy(import("~/components/app/error/Forbidden.vue")),
      meta: {
        layout: "AuthLayout",
      },
    },
    {
      path: "/errors/integration",
      name: "integration-error",
      component: lazy(import("~/components/app/error/Integration.vue")),
      meta: {
        layout: "AuthLayout",
      },
    },
    {
      path: "/errors/forbidden",
      name: "forbidden-error",
      component: lazy(import("~/components/app/error/Forbidden.vue")),
      meta: {
        layout: "AuthLayout",
      },
    },
    {
      path: "/errors/integrated",
      name: "integrated-error",
      component: lazy(import("~/components/app/error/Integrated.vue")),
      meta: {
        layout: "AuthLayout",
      },
    },
    {
      path: "/errors/chatbot",
      name: "chatbot-error",
      component: lazy(import("~/components/app/error/ChatBot.vue")),
      meta: {
        layout: "AuthLayout",
      },
    },
    {
      path: "/profile",
      name: "profile",
      component: ProfileView,
      meta: {
        pageTitle: "menu.profile",
      },
    },
    // {
    //   path: '/profile',
    //   name: 'profile',
    //   component: lazy(import('~/views/ProfileView.vue')),
    //   meta: {
    //     pageTitle: 'menu.profile',
    //   },
    //   children: [
    //     {
    //       path: '',
    //       name: 'profileIndex',
    //       component: ProfileIndex,
    //       meta: {
    //         pageTitle: 'menu.profile',
    //       }
    //     },
    //     {
    //       path: 'channels/:id',
    //       name: 'currentChannel',
    //       component: CurrentChannel,
    //       meta: {
    //         pageTitle: 'menu.profile',
    //       },
    //     },
    //     {
    //       path: 'integration-user',
    //       name: 'integrationUser',
    //       component: IntegrationUser,
    //       meta: {
    //         pageTitle: 'menu.profile',
    //       },
    //     },
    //     {
    //       path: 'channels/creation',
    //       name: 'channelCreation',
    //       component: ChannelCreation,
    //       meta: {
    //         pageTitle: 'menu.profile',
    //       },
    //     },
    //   ],
    // },
    // {
    //   path: '/tariffs',
    //   name: 'tariffs',
    //   component: lazy(import('~/views/TariffsView.vue')),
    //   meta: {
    //     pageTitle: 'tariffs.title',
    //     withBackRedirect: false,
    //     background: '#ffffff',
    //   },
    // },
    // {
    //   path: '/customers',
    //   name: 'customers',
    //   component: lazy(import('~/views/customers/CustomersView.vue')),
    //   redirect: '/customers/sender',
    //   children: [],
    // },
    {
      path: "/contacts",
      name: "contacts",
      component: lazy(import("~/views/contacts/ContactsView.vue")),
      children: [
        {
          path: "",
          name: "contactsList",
          component: lazy(import("~/views/contacts/ContactsListView.vue")),
          meta: {
            pageTitle: "menu.customers",
            background: "#FFFFFF",
          },
        },
        {
          path: "upload",
          name: "contactsUpload",
          component: lazy(import("~/views/contacts/ContactsUploadView.vue")),
          meta: {
            pageTitle: "menu.customers-upload",
            withBackRedirect: false,
          },
        },
        {
          path: "upload/:id",
          name: "contactsSaving",
          component: lazy(import("~/views/contacts/ContactsSavingView.vue")),
          meta: {
            pageTitle: "menu.customers-saving",
            withBackRedirect: true,
            notificationOffset: 74,
          },
        },
        {
          path: 'upload/v2/new',
          name: 'contactUploadV2',
          component: lazy(import('~/views/contacts/v2/Upload.vue')),
          meta: {
            pageTitle: 'menu.customers-upload',
            withBackRedirect: true,
          }
        },
      ],
    },
    {
      path: "/",
      redirect: { name: "profile" },
    },
  ],
});

const subscriptionGuardRoutes = router.getRoutes().filter((route) => {
  if (
    route.path.includes("login") ||
    route.path.includes("register") ||
    route.path.includes("profile") ||
    route.name === "contactsList" ||
    route.name === "contactsUpload" ||
    route.name === "contactsSaving" ||
    route.name === "statistics" ||
    route.name === "tariffs"
  ) {
    return route;
  }
});

router.beforeEach(authGuard(authPaths));
router.beforeEach((to) => {
  const notificationStore = useNotification();

  notificationStore.setOffset(0);

  if (to.meta?.notificationOffset) {
    notificationStore.setOffset(to.meta?.notificationOffset as number);
  }
});
router.beforeEach(initUser);
// router.beforeEach(noActiveSubscriptionGuard(subscriptionGuardRoutes));
// router.beforeEach(customersGuard);
// router.beforeEach(profileGuard);
// router.beforeEach(chatbotGuard);
// router.beforeEach(integrationUserGuard);

export default router;
