import { RouteLocation } from 'vue-router';
import { api } from '~/api';
import { useAuth } from '~/stores/auth.store';

export const authGuard = (publicPaths: string[]) => async (to: RouteLocation) => {
  const auth = useAuth();
  if(!publicPaths.includes(to.path)){
    const res = await api.auth.checkAuth()
    if(res?.status === 401){
      auth.clearAuthData()
      return '/login';
    }
  }

    if (!publicPaths.includes(to.path) && !auth.authorized) {
      await auth.fetchUser();
    }

  if (!publicPaths.includes(to.path) && !auth.authorized) {
    return { name: "login" };
  }
  };
